import React from "react";
import { Avatar, makeStyles, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  flexColumnCenter: {
    alignItems: "center",
    display: "flex",
    flex: 2,
    flexDirection: "column"
  },
  image: {
    height: 128,
    width: 128
  },
  imageContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: 8
    }
  },
  marginBottom: {
    marginBottom: 8
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    margin: 16,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      flexDirection: "column"
    }
  },
  rootReverse: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    margin: 16,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      flexDirection: "column"
    }
  },
  spacer: {
    flex: 1
  },
  textCentered: {
    color: theme.palette.secondary.main,
    textAlign: "center"
  }
}));

function ServiceItem({ description, image, index, title }) {
  const classes = useStyles();

  return (
    <div className={index % 2 !== 0 ? classes.root : classes.rootReverse}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={image} />
      </div>
      <div className={classes.flexColumnCenter}>
        <Avatar className={classNames(classes.avatar, classes.marginBottom)}>
          {index}
        </Avatar>
        <Typography
          className={classNames(classes.textCentered, classes.marginBottom)}
          variant="body2"
        >
          {title}
        </Typography>
        <Typography className={classes.textCentered} variant="body1">
          {description}
        </Typography>
      </div>
      <div className={classes.spacer} />
    </div>
  );
}

ServiceItem.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

export default ServiceItem;
