import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import cx from "classnames";
import ServiceBubble from "../components/services/ServiceBubble";
import ServiceItem from "../components/services/ServiceItem";
import ServiceDirection from "../components/services/ServiceDirection";
import serviceAction from "../images/action.png";
import serviceCasual from "../images/casual.png";
import serviceParty from "../images/party.png";
import serviceRPG from "../images/rpg.png";
import serviceStory from "../images/story.png";
import serviceAnalysing from "../images/serviceAnalysing.png";
import serviceDesign from "../images/serviceDesign.png";
import serviceDevelopment from "../images/serviceDevelopment.png";
import servicePublishing from "../images/servicePublishing.png";
import serviceTesting from "../images/serviceTesting.png";
import SEO from "../components/seo";

const useStyles = makeStyles(theme => ({
  backgroundColor: {
    backgroundColor: theme.palette.secondary.main
  },
  flexColumnCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  flexWrapCenter: {
    alignItems: "end",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "100%",
    justifyContent: "center"
  },
  halfScreen: {
    height: "50vh"
  },
  maxWidth: {
    maxWidth: 960
  },
  paddingAll: {
    padding: "70px 15px"
  },
  textBlock: {
    padding: "70px 15px"
  },
  textBlockContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "0 auto",
    width: "100%"
  },
  textCentered: {
    color: theme.palette.secondary.main,
    marginBottom: 16,
    textAlign: "center"
  },
  textWhiteCentered: {
    color: "#fff",
    marginBottom: 16,
    textAlign: "center"
  }
}));

function Services() {
  const classes = useStyles();

  return (
    <>
      <SEO title={"Diensleistungen"} />
      <div
        className={cx(
          classes.paddingAll,
          classes.backgroundColor,
          classes.halfScreen
        )}
      >
        <div className={classes.textBlockContent}>
          <Typography className={classes.textWhiteCentered} variant="headline">
            Wir sind Ihre Experten in den Bereichen
          </Typography>
          <div className={classes.flexWrapCenter}>
            <ServiceBubble image={serviceAction} title="Party" />
            <ServiceBubble image={serviceCasual} title="Gehirnjogging" inset />
            <ServiceBubble image={serviceParty} title="Aktion" />
            <ServiceBubble image={serviceRPG} title="RPG" inset />
            <ServiceBubble image={serviceStory} title="Storytelling" />
          </div>
        </div>
      </div>
      <div className={classes.textBlock}>
        <div className={cx(classes.textBlockContent, classes.maxWidth)}>
          <Typography className={classes.textCentered} variant="headline">
            Unser Arbeitsablauf
          </Typography>
          <div className={classes.flexColumnCenter}>
            <ServiceItem
              title="Analyse & Angebot"
              description="Spiele können ziemlich komplex werden. Am Anfang besprechen wir zusammen die Idee und erarbeiten einen Zeitplan mit einem Angebot."
              image={serviceAnalysing}
              index={1}
            />
            <ServiceDirection />
            <ServiceItem
              title="Entwicklung"
              description="In einem iterativen Entwicklungsprozess geben wir genug Freiraum um transparent mit Ihnen die Idee Wirklichkeit werden zu lassen"
              image={serviceDevelopment}
              index={2}
            />
            <ServiceDirection />
            <ServiceItem
              title="Layout- & Designkonzept"
              description="Gemeinsam entwickeln wir aus verschiedenen Designentwürfen ein rundes visuelles Konzept zur finalen Umsetzung. "
              image={serviceDesign}
              index={3}
            />
            <ServiceDirection />
            <ServiceItem
              title="Testing"
              description="Um die Qualität zu festigen folgt eine optionale Testphase in der wir das Produkt auf Benutzerfreundlichkeit und Wirkung direkt potenziellen Endverbrauchen vorstellen"
              image={serviceTesting}
              index={4}
            />
            <ServiceDirection />
            <ServiceItem
              title="Veröffentlichung / Abgabe"
              description="Nach der Fertigstellung erhalten sie von uns das Endprodukt. Sollten keine eigenen Server oder Appstore Accounts bestehen übernehmen wir gerne die Veröffentlichung und nachfolgende Wartung"
              image={servicePublishing}
              index={5}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
