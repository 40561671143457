import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";
import cx from "classnames";

const useStyles = makeStyles(theme => ({
  flexEnd: {
    alignSelf: "flex-end"
  },
  flexStart: {
    alignSelf: "flex-start"
  },
  image: {
    alignSelf: "center",
    height: "auto",
    marginBottom: 16,
    width: "100%"
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    margin: 16,
    maxWidth: "calc(20% - 32px - 48px)",
    minWidth: 100,
    [theme.breakpoints.down("md")]: {
      maxWidth: "calc(20% - 32px)"
    }
  },
  textWhiteCentered: {
    color: "#fff",
    textAlign: "center"
  }
}));

function ServiceBubble({ image, inset, title }) {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.root, inset ? classes.flexEnd : classes.flexStart)}
    >
      <img className={classes.image} src={image} />
      <Typography className={classes.textWhiteCentered} variant="body2">
        {title}
      </Typography>
    </div>
  );
}

ServiceBubble.propTypes = {
  image: PropTypes.string.isRequired,
  inset: PropTypes.bool,
  title: PropTypes.string.isRequired
};

export default ServiceBubble;
